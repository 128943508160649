
import React, { useState } from 'react';
import { Box, Button, Container, Paper, Typography, CircularProgress, IconButton, Dialog, DialogTitle, DialogContent, Alert } from '@mui/material';
import useVoicesOpenCall from '../../hooks/useVoicesOpenCall';

import CloseIcon from '@mui/icons-material/Close';
import SectionForm from '../SectionForm/SectionForm';

function OpenCallForm() {
    const { voices, loading: voicesLoading, error: voicesError } = useVoicesOpenCall();
    const [instructionsOpen, setInstructionsOpen] = useState(true);

    const handleInstructionsOpen = () => setInstructionsOpen(true);
    const handleInstructionsClose = () => setInstructionsOpen(false);

    const [sections, setSections] = useState({
        background: ''
    });

    const handleSectionChange = (sectionName, value) => {
        setSections(prevSections => ({ ...prevSections, [sectionName]: value }));
    };

    return (
        <Container className="container">
            {voicesLoading ? <CircularProgress /> : voicesError ? (
                 <Alert severity="error">Error loading voices: {voicesError}</Alert>
                ) : (
                <Paper className="paper">
                    <Box marginBottom={2}>
                        <Button variant="outlined" color="primary" onClick={handleInstructionsOpen} size="large">
                            Instructies
                        </Button>
                        <Dialog open={instructionsOpen} onClose={handleInstructionsClose} aria-labelledby="instructions-dialog-title">
                            <DialogTitle id="instructions-dialog-title">
                                <h3>Instructies</h3>
                                <IconButton edge="end" color="inherit" onClick={handleInstructionsClose} aria-label="close" style={{ position: 'absolute', right: '10px', top: '10px' }}>
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent>
                                <ul>
                                    <li>Stap 1. Kies persona.</li>
                                    <li>Stap 2. Schrijf een stukje Open Call tekst.</li>
                                    <li>Stap 3. Klik: geef feedback.</li>
                                </ul> 
                            </DialogContent>
                        </Dialog>
                    </Box>
                  

                <Box className="typographyHeader">
                    <Typography variant="h5" style={{ marginTop: '50px' }}>
                        Wat is de achtergrond van je open call?
                    </Typography>
                </Box>   

                <SectionForm 
                    label="Achtergrond" 
                    value={sections.background} 
                    onChange={(value) => handleSectionChange('background', value)}
                    selectedVoices={voices}
                />
                
                </Paper>
            )}    
        </Container>
    );
}

export default OpenCallForm;
