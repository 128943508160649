import React, { useState } from 'react';
import { Box, Button, Container, Paper, Typography, CircularProgress, IconButton, Dialog, DialogTitle, DialogContent, Alert, Select, MenuItem, Slider } from '@mui/material';
import useVoicesOpenCall from '../../hooks/useVoicesOpenCall';

import CloseIcon from '@mui/icons-material/Close';
import FeedbackSectionForm from '../FeedbackSectionForm/FeedbackSectionForm';

function FeedbackForm() {
    const { voices, loading: voicesLoading, error: voicesError } = useVoicesOpenCall();
    const [instructionsOpen, setInstructionsOpen] = useState(true);

    const handleInstructionsOpen = () => setInstructionsOpen(true);
    const handleInstructionsClose = () => setInstructionsOpen(false);

    const [sections, setSections] = useState({
        text_input: '',
        format: '', 
        tone: 50,
    });

    const handleSectionChange = (sectionName, value) => {
        setSections(prevSections => ({ ...prevSections, [sectionName]: value }));
    };

    // function to handle change in feedback format
    const handleFormatChange = (event) => {
        handleSectionChange('format', event.target.value);
    };

    // function to handle change in feedback tone
    const handleToneChange = (event, newValue) => {
        handleSectionChange('tone', newValue);
    };

    return (
        <Container className="container">
            {voicesLoading ? <CircularProgress /> : voicesError ? (
                 <Alert severity="error">Error loading voices: {voicesError}</Alert>
                ) : (
                <Paper className="paper">
           
                    <Box className="typographyHeader">
                        <Typography variant="h5" style={{ marginTop: '50px' }}>
                            Feedback Format
                        </Typography>
                    </Box>   


                    {/* new field for feedback format */}
                    <Select
                        value={sections.format}
                        onChange={handleFormatChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        <MenuItem value="" disabled>
                            Select a feedback format
                        </MenuItem>
                        <MenuItem value={'theater_audience_gossip'}>Gossip by the audience</MenuItem>
                        <MenuItem value={'theater_expert_panel'}>Expert Panel</MenuItem>
                        <MenuItem value={'theater_youth_panel'}>Youth Panel</MenuItem>
                        <MenuItem value={'theater_backstage_confessions'}>Backstage Confessions</MenuItem>
                        <MenuItem value={'theater_peer_review'}>Peer review between makers</MenuItem>
                        <MenuItem value={'theater_maker_gossip'}>Performers gossip about audience</MenuItem>
                    </Select>

                 {/* new field for feedback tone */}
                    <Box className="typographyHeader">
                        <Typography variant="h5" style={{ marginTop: '50px' }}>
                            Feedback Tone of Voice
                        </Typography>
                        <Typography variant="body2">
                            Use the slider to set the tone of the feedback. 1 is very polite, 99 is very direct.
                        </Typography>
                    </Box>   

                    <Slider
                        value={sections.tone}
                        onChange={handleToneChange}
                        aria-labelledby="continuous-slider"
                        valueLabelDisplay="auto" // display the current value
                        min={1} // minimum value
                        max={99} // maximum value
                        marks // display marks
                        style={{ marginTop: '0px', marginBottom: '20px' }} // add some margin for better spacing
                    />

                    <Box className="typographyHeader">
                        <Typography variant="h5" style={{ marginTop: '50px' }}>
                            What would you like feedback on?
                        </Typography>
                    </Box>   

                    <FeedbackSectionForm 
                        label="Input for feedback" 
                        value={sections.text_input} 
                        onChange={(value) => handleSectionChange('text_input', value)}
                        selectedVoices={voices}
                        selectedFormat={sections.format}
                        selectedTone={sections.tone}
                    />
                    
                </Paper>
            )}    
        </Container>
    );
}

export default FeedbackForm;