import { useState, useEffect } from 'react';
import axios from 'axios';

const useGenerateSpeechToSpeech = (voiceId, voiceSettings) => {
    const [audioUrl, setAudioUrl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const uploadAudio = async (audioFile) => {
        setLoading(true);
        setError(null);
    
        if (!audioFile || !(audioFile instanceof File || audioFile instanceof Blob)) {
            const errorMessage = 'Invalid or missing audio file.';
            console.error(errorMessage);
            setError(errorMessage);
            setLoading(false);
            return null;
        }
    
        if (!audioFile.type.startsWith('audio/')) {
            const errorMessage = 'Provided file is not an audio file.';
            console.error(errorMessage);
            setError(errorMessage);
            setLoading(false);
            return null;
        }
    
        if (audioFile.size === 0) {
            const errorMessage = 'Provided audio file is empty.';
            console.error(errorMessage);
            setError(errorMessage);
            setLoading(false);
            return null;
        }
    
        console.log('Audio file is valid and has content.');
    
        try {
            const formData = new FormData();
            formData.append('file', audioFile);
    
            console.log('Uploading audioFile:', audioFile);
    
            const uploadResponse = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/upload-audio`, 
                formData, 
                { headers: { 'Content-Type': 'multipart/form-data' } }
            );
            if (uploadResponse.data && uploadResponse.data.path) {
                console.log('Upload successful, path:', uploadResponse.data.path);
                return uploadResponse.data.path;
            } else {
                throw new Error('No path returned from upload');
            }
        } catch (error) {
            console.error('Upload failed:', error);
            setError(`Upload failed: ${error.response ? error.response.data.error : error.message}`);
            setLoading(false);
            return null;
        }
    };
    
    const generateSpeech = async (audioPath) => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/generate-speech-to-speech`,
                { audioPath, voiceId, voiceSettings }
            );
            setLoading(false);
            if (response.data && response.data.task_id) {
                return response.data.task_id;  // Make sure taskId is being returned
            } else {
                throw new Error('No task ID returned from generation');
            }
        } catch (error) {
            setError(`Generation failed: ${error.message}`);
            setLoading(false);
            return null;  // Return null if there's an error
        }
    };

    let intervalId = null;

    const checkTaskStatus = async (taskId) => {
        try {
            const statusResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/task-status/${taskId}`);
            const data = statusResponse.data;
            console.log('Task status: ', data.status);
            if (data.status === 'Task completed') {
                console.log('Task completed, data:', data);
                console.log('Task completed, audio URL:', data.result.audioUrl);
                setAudioUrl(data.result.audioUrl);
                clearInterval(intervalId); // Stop polling
            } else if (data.status === 'Task failed') {
                setError(data.error);
                clearInterval(intervalId); // Stop polling
            }
        } catch (error) {
            setError(`Error checking task status: ${error.message}`);
        }
    };

    useEffect(() => {
        intervalId = setInterval(() => {
            if (audioUrl) {
                clearInterval(intervalId);
            }
        }, 3000); // Poll every 3 seconds only if we are waiting for a task to complete

        return () => clearInterval(intervalId);
    }, [audioUrl]); // Only rerun the effect if audioUrl changes

    return { uploadAudio, generateSpeech, checkTaskStatus, audioUrl, loading, error };
};

export default useGenerateSpeechToSpeech;