// src/components/SentenceForm/SentenceForm.js
import React from 'react';
import {useState , useEffect, useCallback} from 'react';
import { Box, Autocomplete, TextField, IconButton, InputAdornment, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import config from '../../config';



const SentenceForm = ({ index, sentence, voices, bgVoices, loadingBgVoices, onSentenceChange, onRemoveSentence, selectedFormat }) => {    
    
    
    const bgVoiceFolder = `${config.SITE_NAME}/bgvoice/`;
    const bgVoiceId = sentence.bgVoiceId || '';
    const faceId = sentence.faceId || '';

    const [hasSelectedVoice, setHasSelectedVoice] = useState(false);
    const [hasSelectedBgVoice, setHasSelectedBgVoice] = useState(false);
    const [hasSelectedFace, setHasSelectedFace] = useState(false);
    const [filteredBgVoices, setFilteredBgVoices] = useState([]);

    const handleBgVoiceChange = useCallback((index, filename) => {
        onSentenceChange(index, 'bgVoiceId', filename);
    }, [onSentenceChange]);

    const handleFaceChange = useCallback((index, url) => {
        onSentenceChange(index, 'faceId', url);
    }, [onSentenceChange]);

    useEffect(() => {
        if (selectedFormat) {
            const newFilteredBgVoices = bgVoices.filter(file => file.filename.startsWith(`${selectedFormat}_`));
            setFilteredBgVoices(newFilteredBgVoices);
    
            if (!hasSelectedBgVoice) {
                handleBgVoiceChange(index, '');
                setHasSelectedBgVoice(true);
            }
        } else {
            setFilteredBgVoices(bgVoices);
    
            if (!hasSelectedBgVoice) {
                handleBgVoiceChange(index, '');
                setHasSelectedBgVoice(true);
            }
        }
    }, [selectedFormat, bgVoices, hasSelectedBgVoice, handleBgVoiceChange, index]);

    useEffect(() => {
        if (!hasSelectedVoice && voices.length > 0 && !sentence.voiceId) {
            const newVoice = voices[Math.floor(Math.random() * voices.length)];
            onSentenceChange(index, 'voiceId', newVoice.voice_id);
            setHasSelectedVoice(true);
        }
    }, [voices, sentence.voiceId, selectedFormat, filteredBgVoices, hasSelectedVoice, onSentenceChange, index]);
    

    
    useEffect(() => {
        if (process.env.REACT_APP_VIDEO_ACCESS === 'yes') {
            if (!hasSelectedFace && faceId) {
                handleFaceChange(index, faceId);
                setHasSelectedFace(true);
            }
        }
    }, [faceId, hasSelectedFace, handleFaceChange, index]);

    
    return (
        <div className="sentenceForm">
            <div className="flexContainer">
                <Box mb={2} className="textField">
                    <TextField
                        label={`Fragment ${index + 1}`}
                        multiline
                        rows={5}
                        fullWidth
                        placeholder="Elk 'fragment' (of spreekbeurt) is gekoppeld aan één stem.
                        Optioneel: voeg achtergrondgeluid zoals treingeluid of straatgeluid aan een fragment toe."
                        variant="outlined"
                        value={sentence.text}
                        onChange={(e) => onSentenceChange(index, 'text', e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => onRemoveSentence(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <div className="flexItem">
                    <div className="formControls">
                        {/* Voice Select */}

                        <Box mb={2} className="formControl">
                            <FormControl fullWidth>
                                <Autocomplete
                                    id={`voice-select-${index}`}
                                    options={voices}
                                    getOptionLabel={(option) => option.name}
                                    value={voices.find(voice => voice.voice_id === sentence.voiceId) || null}
                                    onChange={(event, newValue) => {
                                        onSentenceChange(index, 'voiceId', newValue ? newValue.voice_id : '');
                                    }}
                                    isOptionEqualToValue={(option, value) => option.voice_id === value.voice_id}
                                    renderInput={(params) => <TextField {...params} label="Stem" />}
                                />
                            </FormControl>
                        </Box>
                        {/* BgVoice Select */}
                        <Box mb={2} className="formControl">
                            <FormControl fullWidth>
                                <InputLabel id={`bgvoice-select-label-${index}`}>Achtergrondgeluid</InputLabel>
                                <Select
                                    labelId={`bgvoice-select-label-${index}`}
                                    id={`bgvoice-select-${index}`}
                                   // Only show filename value={bgVoiceId ? bgVoiceId.replace(bgVoiceFolder, '') : ''} 
                                   value={bgVoiceId}                                    
                                   label="Achtergrondgeluid"
                                    onChange={(e) => handleBgVoiceChange(index, e.target.value)}
                                    disabled={loadingBgVoices} // Disable select while loading
                                >
                                <MenuItem value="">Geen</MenuItem>
                                {loadingBgVoices ? (
                                    <MenuItem value="">
                                        <em>Loading...</em>
                                    </MenuItem>
                                ) : (
                                bgVoices.map((bgVoice, idx) => (
                                    //<MenuItem key={idx} value={bgVoice.filename}>{bgVoice.filename.replace('.mp3', '')}</MenuItem>
                                    <MenuItem key={idx} value={bgVoiceFolder + bgVoice.filename}>{bgVoice.filename.replace('.mp3', '')}</MenuItem>
                                ))
                                )}
                                </Select>
                            </FormControl>
                        </Box>

                    {/* Face Select and venv */}
                    {process.env.REACT_APP_VIDEO_ACCESS === 'yes' && (
                        <Box mb={2} className="formControl">
                            <FormControl fullWidth>
                                <InputLabel id={`face-select-label-${index}`}>Praatgezicht</InputLabel>
                                <Select
                                    labelId={`face-select-label-${index}`}
                                    id={`face-select-${index}`}
                                    value={faceId}
                                    label="Praatgezicht"
                                    onChange={(e) => handleFaceChange(index, e.target.value)}
                                >
                                    <MenuItem value="">Geen</MenuItem>
                                    <MenuItem value="voxpop_man_30_china_2">voxpop_man_30_china_2</MenuItem>
                                    <MenuItem value="voxpop_man_30_china_3">voxpop_man_30_china_3</MenuItem>
                                    <MenuItem value="voxpop_man_30_china">voxpop_man_30_china</MenuItem>
                                    <MenuItem value="voxpop_man_30_nl_2">voxpop_man_30_nl_2</MenuItem>
                                    <MenuItem value="voxpop_man_30_nl_3">voxpop_man_30_nl_3</MenuItem>
                                    <MenuItem value="voxpop_man_30_nl_4">voxpop_man_30_nl_4</MenuItem>
                                    <MenuItem value="voxpop_man_30_nl">voxpop_man_30_nl</MenuItem>
                                    <MenuItem value="voxpop_man_80_nl_2">voxpop_man_80_nl_2</MenuItem>
                                    <MenuItem value="voxpop_man_80_nl">voxpop_man_80_nl</MenuItem>
                                    <MenuItem value="voxpop_vrouw_20_suriname_2">voxpop_vrouw_20_suriname_2</MenuItem>
                                    <MenuItem value="voxpop_vrouw_20_suriname">voxpop_vrouw_20_suriname</MenuItem>
                                    <MenuItem value="voxpop_vrouw_20_turkije_2">voxpop_vrouw_20_turkije_2</MenuItem>
                                    <MenuItem value="voxpop_vrouw_20_turkije_3">voxpop_vrouw_20_turkije_3</MenuItem>
                                    <MenuItem value="voxpop_vrouw_20_turkije">voxpop_vrouw_20_turkije</MenuItem>
                                    <MenuItem value="voxpop_vrouw_30_china">voxpop_vrouw_30_china</MenuItem>
                                    <MenuItem value="voxpop_vrouw_30_nl_2">voxpop_vrouw_30_nl_2</MenuItem>
                                    <MenuItem value="voxpop_vrouw_30_nl_3">voxpop_vrouw_30_nl_3</MenuItem>
                                    <MenuItem value="voxpop_vrouw_30_nl_4">voxpop_vrouw_30_nl_4</MenuItem>
                                    <MenuItem value="voxpop_vrouw_30_nl_5">voxpop_vrouw_30_nl_5</MenuItem>
                                    <MenuItem value="voxpop_vrouw_30_nl_6">voxpop_vrouw_30_nl_6</MenuItem>
                                    <MenuItem value="voxpop_vrouw_30_nl_7">voxpop_vrouw_30_nl_7</MenuItem>
                                    <MenuItem value="voxpop_vrouw_30_nl">voxpop_vrouw_30_nl</MenuItem>
                                    <MenuItem value="voxpop_vrouw_40_nl_2">voxpop_vrouw_40_nl_2</MenuItem>
                                    <MenuItem value="voxpop_vrouw_40_nl">voxpop_vrouw_40_nl</MenuItem>
                                    <MenuItem value="voxpop_vrouw_50_suriname_2">voxpop_vrouw_50_suriname_2</MenuItem>
                                    <MenuItem value="voxpop_vrouw_50_suriname">voxpop_vrouw_50_suriname</MenuItem>
                                    <MenuItem value="voxpop_vrouw_80_nl">voxpop_vrouw_80_nl</MenuItem>
                                    <MenuItem value="voxpop_vrouw_80_suriname_2">voxpop_vrouw_80_suriname_2</MenuItem>
                                    <MenuItem value="voxpop_vrouw_80_turkije_2">voxpop_vrouw_80_turkije_2</MenuItem>
                                    <MenuItem value="voxpop_vrouw_80_turkije">voxpop_vrouw_80_turkije</MenuItem>
                                    <MenuItem value="sora_bang_vrouw_1">sora_bang_vrouw_1</MenuItem>
                                    <MenuItem value="sora_bang_man">sora_bang_man</MenuItem>
                                    <MenuItem value="sora_bang_vrouw_2">sora_bang_vrouw_2</MenuItem>
                                    <MenuItem value="sora_happy_man">sora_happy_man</MenuItem>
                                    <MenuItem value="sora_happy_vrouw">sora_happy_vrouw</MenuItem>
                                    <MenuItem value="zoltar_2">zoltar_2</MenuItem>
                                    <MenuItem value="zoltar_1">zoltar_1</MenuItem>
                                    <MenuItem value="sora_bang_man_1">sora_bang_man_1</MenuItem>
                                    <MenuItem value="sora_bang_man_2">sora_bang_man_2</MenuItem>
                                    <MenuItem value="sora_bang_man_3">sora_bang_man_3</MenuItem>
                                    <MenuItem value="sora_bang_vrouw_3">sora_bang_vrouw_3</MenuItem>
                                    <MenuItem value="sora_bang_vrouw_4">sora_bang_vrouw_4</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    )}
                    </div>
                </div>
            </div>

            

        </div>
    );
};

export default SentenceForm;
