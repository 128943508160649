import { useState } from 'react';
import axios from 'axios';

const useSentimentAnalysis = () => {
  const [emotions, setEmotions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const analyzeSentiment = async (text) => {
    if (!text) {
      console.log('No text provided for analysis');
      return;
    }

    console.log(`Analyzing sentiment for text: ${text}`);
    setLoading(true);
    setEmotions(null);
    setError(null);

    try {
      console.log('Sending request to sentiment analysis API');
      const response = await axios.post('http://0.0.0.0:8032/sentiment', { text }, {
        headers: { 'Content-Type': 'application/json' },
        timeout: 10000 // timeout van 5 seconden
      });

      console.log('Response received:', response.data);
      const emotionData = response.data.emotions;
      const sortedEmotions = Object.entries(emotionData)
        .sort((a, b) => b[1] - a[1])  // Sort emotions by their percentage values in descending order
        .slice(0, 5)  // Take top 5 emotions
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

      console.log('Top 5 emotions:', sortedEmotions);
      setEmotions(sortedEmotions);
    } catch (error) {
      console.error('Error during sentiment analysis:', error);
      setError(error.response?.data?.message || 'Failed to analyze sentiment');
    } finally {
      setLoading(false);
      console.log('Loading finished');
    }
  };

  return { emotions, loading, error, analyzeSentiment };
};

export default useSentimentAnalysis;