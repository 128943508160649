import React, {useEffect, useState, useCallback} from 'react';
import { Box, Autocomplete,TextField, FormControl, InputLabel, Typography, Select, MenuItem, Grid } from '@mui/material';
import useAudioFiles from '../../hooks/useAudioFiles';
import config from '../../config' 

function AudioSettingsForm({ audiosettings, setAudioSettings, selectedFormat, setSelectedFormat, tags }) {
    
  const { audioFiles: introFiles } = useAudioFiles(`${config.SITE_NAME}/intros`);
  const { audioFiles: outroFiles } = useAudioFiles(`${config.SITE_NAME}/outros`);
  const { audioFiles: bgaudioFiles } = useAudioFiles(`${config.SITE_NAME}/bgaudio`);

  const [filteredIntroFiles, setFilteredIntroFiles] = useState([]);
  const [filteredOutroFiles, setFilteredOutroFiles] = useState([]);
  const [filteredBgaudioFiles, setFilteredBgaudioFiles] = useState([]);

  const handleSettingChange = useCallback((setting, value) => {
    setAudioSettings(prevSettings => ({
      ...prevSettings,
      [setting]: value
    }));
  }, [setAudioSettings]);

  useEffect(() => {
    if (selectedFormat) {
        setFilteredIntroFiles(introFiles.filter(file => file.filename.startsWith(`${selectedFormat}_`)));
        setFilteredOutroFiles(outroFiles.filter(file => file.filename.startsWith(`${selectedFormat}_`)));
        setFilteredBgaudioFiles(bgaudioFiles.filter(file => file.filename.startsWith(`${selectedFormat}_`)));
    } else {
        setFilteredIntroFiles([]);
        setFilteredOutroFiles([]);
        setFilteredBgaudioFiles([]);
    }
}, [selectedFormat, introFiles, outroFiles, bgaudioFiles]);

useEffect(() => {
    const newIntro = filteredIntroFiles[Math.floor(Math.random() * filteredIntroFiles.length)];
    const newOutro = filteredOutroFiles[Math.floor(Math.random() * filteredOutroFiles.length)];
    const newBgAudio = filteredBgaudioFiles[Math.floor(Math.random() * filteredBgaudioFiles.length)];

    handleSettingChange('intro', newIntro ? newIntro.filename : '');
    handleSettingChange('outro', newOutro ? newOutro.filename : '');
    handleSettingChange('bgaudio', newBgAudio ? newBgAudio.filename : '');
}, [filteredIntroFiles, filteredOutroFiles, filteredBgaudioFiles, handleSettingChange]);

    useEffect(() => {
      handleSettingChange('format', selectedFormat || '');
    }, [selectedFormat, handleSettingChange]);

  return (
    <div className="AudioSettingsForm">
      <Box className="typographyHeader">
      <Typography variant="h5" className="typographyHeader">
        Algemene instellingen voor je audioformat
      </Typography>
      <p>Kies een format (filter), kies een intro, kies een outro en/of kies achtergrondmuziek. </p>
      </Box>
      <Grid container spacing={2}>

        {/* Format select */}
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <Autocomplete
                id="subproject-label"
                options={tags}
                getOptionLabel={(option) => option}
                value={selectedFormat}
                onChange={(event, newValue) => {
                  setSelectedFormat(newValue);
                  handleSettingChange('format', newValue || '');
                }}
                renderInput={(params) => <TextField {...params} label="Filter voor audio en stemmen" />}
              />
            </FormControl>
          </Grid>

        
        {/* Intro select */}
        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <InputLabel id="intro-label">Intro (start van je audiobestand)</InputLabel>
            <Select
              labelId="intro-label"
              value={audiosettings.intro}
              onChange={(e) => handleSettingChange('intro', e.target.value)}
              >
              <MenuItem value="">Geen</MenuItem>
              {filteredIntroFiles.map((file, index) => (
                  <MenuItem key={index} value={file.filename}>{file.filename.replace('.mp3', '')}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* BGaudio select */}
        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <InputLabel id="bgaudio-label">Achtergrond (audio op de achtergrond)</InputLabel>
            <Select
              labelId="bgaudio-label"
              value={audiosettings.bgaudio}
              onChange={(e) => handleSettingChange('bgaudio', e.target.value)}
              >
              <MenuItem value="">Geen</MenuItem>
              {filteredBgaudioFiles.map((file, index) => (
                  <MenuItem key={index} value={file.filename}>{file.filename.replace('.mp3', '')}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        
        {/* Outro select */}
        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <InputLabel id="outro-label">Outro (einde van je audiobestand)</InputLabel>
            <Select
              labelId="outro-label"
              value={audiosettings.outro}
              onChange={(e) => handleSettingChange('outro', e.target.value)}
              >
              <MenuItem value="">Geen</MenuItem>
              {filteredOutroFiles.map((file, index) => (
                  <MenuItem key={index} value={file.filename}>{file.filename.replace('.mp3', '')}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        
      </Grid>
    </div>
  );
}

export default AudioSettingsForm;
