import React, { useState, useEffect } from 'react';
import { Button, Box, CircularProgress, Typography, IconButton, ToggleButton, ToggleButtonGroup, Grid, Paper } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import SendIcon from '@mui/icons-material/Send';
import GetAppIcon from '@mui/icons-material/GetApp';
import useGenerateSpeechToSpeech from '../../hooks/useGenerateSpeechToSpeech';

function SpeechTransformer({ voiceId, voiceSettings, onTransformedUrlChange }) {
    const [audioFile, setAudioFile] = useState(null);
    const [transformedUrl, setTransformedUrl] = useState(null);
    const [recording, setRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [audioSource, setAudioSource] = useState('record');
    const [hasRecorded, setHasRecorded] = useState(false); // Correctly declare this state variable
    const { uploadAudio, generateSpeech, audioUrl, checkTaskStatus } = useGenerateSpeechToSpeech(voiceId, voiceSettings); // Include checkTaskStatus from the hook
    const [taskId, setTaskId] = useState(null); // State to track task ID       
    
    
    const handleUploadAndGenerate = async () => {
        if (!audioFile) {
            setError('No file selected.');
            return;
        }
        setLoading(true);
        setError('');
        try {
            const audioPath = await uploadAudio(audioFile);
            if (!audioPath) {
                setError('Failed to upload audio.');
                setLoading(false);
                return;
            }
            const newTaskId = await generateSpeech(audioPath);
            if (newTaskId) {
                setTaskId(newTaskId);  // Set the taskId from the response
            } else {
                setError('Failed to start generation task.');
                setLoading(false);
            }
        } catch (err) {
            setError(`Upload Error: ${err.message}`);
            setLoading(false);
        }
    };
    
    useEffect(() => {
        const interval = setInterval(() => {
            if (taskId) {
                checkTaskStatus(taskId).then(() => {
                    if (audioUrl) {
                        setTransformedUrl(audioUrl);
                        onTransformedUrlChange(audioUrl);
                        console.log('Transformed URL:', audioUrl);
                        setLoading(false);
                        clearInterval(interval);
                        taskId && setTaskId(null);
                    }
                });
            }
        }, 2000);
    
        return () => clearInterval(interval);
    }, [taskId, audioUrl, checkTaskStatus, onTransformedUrlChange]);
    

    useEffect(() => {
        async function setupRecorder() {
            if (recording && !mediaRecorder && audioSource === 'record') {
                try {
                    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                    const newRecorder = new MediaRecorder(stream);
                    newRecorder.ondataavailable = e => {
                        const newAudioFile = new File([e.data], `recording-${Date.now()}.mp3`, { type: 'audio/mp3' });
                        setAudioFile(newAudioFile);
                        setHasRecorded(true);
                    };
                    newRecorder.start();
                    setMediaRecorder(newRecorder);
                } catch (err) {
                    console.error('Error setting up media recorder:', err);
                    setError(err.message);
                }
            } else if (!recording && mediaRecorder) {
                mediaRecorder.stop();
                mediaRecorder.stream.getTracks().forEach(track => track.stop());
                setMediaRecorder(null);
            }
        }

        setupRecorder();
    }, [recording, audioSource]);

    const handleRecording = () => {
        setRecording(!recording);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: acceptedFiles => {
            setAudioFile(acceptedFiles[0]);
            setHasRecorded(true); // Make sure this is reset appropriately
        },
        noClick: audioSource !== 'upload',
        noKeyboard: audioSource !== 'upload'
    });




    return (
        <Box p={2}>
            <ToggleButtonGroup
                value={audioSource}
                exclusive
                onChange={(event, newAudioSource) => {
                    setAudioSource(newAudioSource);
                    setRecording(false);
                }}
                fullWidth
                color="primary"
            >
                <ToggleButton value="record" aria-label="record audio">
                    <MicIcon />
                </ToggleButton>
                <ToggleButton value="upload" aria-label="upload audio">
                    <UploadFileIcon />
                </ToggleButton>
            </ToggleButtonGroup>
    
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={12} container spacing={2}>
                    <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'center' }}>
    
                    {audioSource === 'upload' && (
                        <Paper elevation={3} style={{ padding: '20px', margin: '10px 0', width: '100%', maxWidth: '100%' }}>
                            <Box {...getRootProps({ className: 'dropzone' })} p={2} border="1px solid #ccc" style={{ cursor: 'pointer', width: '90%' }}>
                                <input {...getInputProps()} />
                                <Typography>Drag 'n' drop some files here, or click to select files</Typography>
                            </Box>
                        </Paper>
                    )}
                    
                    {audioSource === 'record' && (
                        <Paper elevation={3} style={{ padding: '20px', margin: '10px 0', width: '100%', maxWidth: '100%' }}>
                            
                        {audioFile && (
                                <>
                                    <audio controls src={URL.createObjectURL(audioFile)} style={{ width: '100%' }} />
                                    <IconButton color="primary" component="a" href={URL.createObjectURL(audioFile)} download>
                                    <Typography variant="body2" gutterBottom>Download Origineel </Typography> <GetAppIcon />
                                    </IconButton>
                                </>
                        )}
                            
                                <Button 
                                    onClick={handleRecording} 
                                    startIcon={recording ? <StopIcon /> : <MicIcon />}
                                    variant="contained"
                                    style={{ backgroundColor: recording ? '#d32f2f' : '#4caf50' }} // Red for stop, green for start
                                    disabled={loading}
                                >
                                    {recording ? 'Stop Recording' : (hasRecorded ? 'Opnieuw opnemen' : 'Start Recording')}
                                </Button>
                        </Paper>
                    )} 
    
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'center' }}>
                    
                        <Paper elevation={3} style={{ padding: '20px', margin: '10px 0', width: '100%', maxWidth: '100%' }}>
                            
                        {transformedUrl && (
                            <>
                                <audio controls src={transformedUrl} style={{ width: '100%' }} />
                                <IconButton color="primary" component="a" href={transformedUrl} download>
                                <Typography variant="body2" gutterBottom>Download met nieuwe stem </Typography> <GetAppIcon />
                                </IconButton>
                            </>
                        )}
    
                        {audioFile && !loading && (
                            <Button
                                variant="contained"
                                color="primary"
                                style= {{ marginLeft: '10px' }}
                                onClick={handleUploadAndGenerate}
                                disabled={loading || !audioFile}
                                startIcon={loading ? <CircularProgress size={20} /> : <SendIcon />}
                            >
                                Verander in gekozen stem
                            </Button>
                        )}
    
                        {/* Progress indicator and status messages */}
                        {loading && (
                        <>
                            <CircularProgress size={24} />
                            <Typography>{taskId || "Bestand verwerken.."}</Typography>
                        </>
                    )}
                    {error && <Typography color="error">{error}</Typography>}
                        </Paper>
                      
                        
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
    
}

export default SpeechTransformer;
