import { useState, useEffect } from 'react';
import { TextField, Button, Box, CircularProgress } from '@mui/material';
import useChat from '../../hooks/useChat';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const ChatForm = ({ onChatResponse, selectedFormat }) => {
    const [input, setInput] = useState('');
    const { response, chat, loading, error, taskStatus, taskId } = useChat();

    const handleInputChange = (event) => {
        setInput(event.target.value);
    };

    useEffect(() => {
        if (response && taskStatus === 'Task completed') {
            onChatResponse(response);
        }
    }, [response, taskStatus, onChatResponse]);

    const handleButtonClick = async (event) => {
        event.preventDefault();
        try {
            await chat(input,selectedFormat);
        } catch (error) {
            console.error(error);
        }
        // De response wordt afgehandeld in de useEffect van useChat
    };

    const [openSnackbar, setOpenSnackbar] = useState(false);

    useEffect(() => {
        if (error) {
            setOpenSnackbar(true);
        }
    }, [error]);

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    return (
        <Box
            component="form"
            id="chat-form"
            onSubmit={handleButtonClick}
            display="flex"
            flexDirection="column"
            gap={2}
        >
            <TextField
                id="input-field"
                label="Wat wil je in een script gieten?"
                multiline
                placeholder="Benoem een onderwerp (bijv. 'voxpop over Socrates') of deel een hele lap aan data/tekst."
                rows={4}
                value={input}
                onChange={handleInputChange}
                variant="outlined"
                disabled={loading} 
                InputProps={{
                    endAdornment: (
                        <div style={{ width: '20%', marginLeft: '20px', textAlign: 'right' }}>
                            <Button 
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={loading || taskStatus === 'Task in progress'}
                            >
                                {loading || taskStatus === 'Task in progress' ? (
                                    <>
                                        <CircularProgress size={24} />
                                        {taskStatus === 'Task in progress' ? 'Verwerken...' : 'Taak wordt verwerkt...'}
                                    </>
                                ) : taskId ? 'Opnieuw verzenden' : 'Maak er een script van'}
                            </Button>
                        </div>
                    ),
                }}
                inputProps={{ style: { resize: "both" } }}
            />

{error && (
    <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {error.toString()}
        </Alert>
    </Snackbar>
)}

    </Box>

    );
};

export default ChatForm;