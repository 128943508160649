// src/hooks/useVoices.js
import { useState, useEffect } from 'react';
import axios from 'axios'; 
import config from '../config';

const useVoices = () => {
    const [voices, setVoices] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchVoices = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get_voices_with_label/type/${config.SITE_NAME}`);
                console.log("Response data:", response.data); // Log response data
                setVoices(response.data);
            } catch (error) {
                console.error('Error fetching voices:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchVoices();
    }, []);

    return { voices, loading };
};

export default useVoices;