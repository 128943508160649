// src/hooks/useGenerateSpokenFeedback.js
import { useState, useEffect } from 'react';
import axios from 'axios'; // importeer axios
import { v4 as uuidv4 } from 'uuid'; // Import UUID to generate a unique idempotency key

const useGenerateSpokenFeedback = () => {
    const [audioUrl, setAudioUrl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [taskId, setTaskId] = useState(null);
    const [taskStatus, setTaskStatus] = useState(null);

    const generateVoices = async (textVoicePairs, audioSettings) => {
        setLoading(true);
        setError(null);
        setAudioUrl(null); // Reset the audioUrl
        setTaskStatus('Task in progress'); // Set the task status to 'Task in progress'

        // Generate a unique idempotency key for each request
        const idempotencyKey = uuidv4(); 

        try {
            const introPath = audioSettings.intro ? `${process.env.REACT_APP_API_BASE_URL}/intros/${audioSettings.intro}` : '';
            const outroPath = audioSettings.outro ? `${process.env.REACT_APP_API_BASE_URL}/outros/${audioSettings.outro}` : '';
            const bgAudioPath = audioSettings.bgaudio ? `${process.env.REACT_APP_API_BASE_URL}/bgaudio/${audioSettings.bgaudio}` : '';
            const showformat = audioSettings.format || '';

            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/generate-multiple-voices`, 
                { textVoicePairs, intro: introPath, outro: outroPath, bgaudio: bgAudioPath, showformat },
                { headers: { 'Content-Type': 'application/json', 'Idempotency-Key': idempotencyKey } }
            );
            setTaskId(response.data.task_id);
        } catch (e) {
            setError(e.response?.data?.error || e.message);
            setLoading(false); // Stop loading when an error occurs
        }
    };

    useEffect(() => {
        let intervalId;
    
        const checkTaskStatus = async () => {
            if (taskId) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/task-status/${taskId}`);
                    const data = response.data;
    
                    if (data.status === 'Task completed') {
                        clearInterval(intervalId);
                        if (data.result && data.result.status === 'Error') {
                            setError(data.result.message || 'Error while processing the task');
                        } else {
                            setAudioUrl(data.result.audio_file);
                        }
                        setLoading(false); // Stop loading when the task is completed
                        setTaskId(null);
                    } else {
                        setTaskStatus(data.status);
                    }
                } catch (error) {
                    clearInterval(intervalId);
                    setError('Error while fetching task status');
                    setLoading(false); // Stop loading when an error occurs
                    setTaskId(null);
                }
            }
        };
    
        if (taskId) {
            intervalId = setInterval(checkTaskStatus, 5000); // Check every 5 seconds
        }
    
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [taskId]);

    return { audioUrl, generateVoices, loading, error, taskStatus };
};

export default useGenerateSpokenFeedback;
