import { useState, useEffect } from 'react';
import axios from 'axios';

const useChatOpenCall = () => {
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [taskId, setTaskId] = useState(null);

    const chat = async (userInput, selectedVoices) => {
        if (loading) return; // Prevent concurrent executions

        setLoading(true);
        setError(null);
        setResponse(null);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/chat`, 
                {
                    input: `${userInput} Gebruik deze voice_names in je format: ${selectedVoices.join(', ')}`,
                    format: 'opencall'
                },
                { headers: { 'Content-Type': 'application/json' } }
            );
            setTaskId(response.data.task_id);
        } catch (error) {
            setError(error.response?.data?.message || error.message);
        }
    };

    useEffect(() => {
        let intervalId;
    
        const checkTaskStatus = async () => {
            if (!taskId) return;
    
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/task-status/${taskId}`);
                const data = res.data;
    
                if (data.status === 'Task completed' || data.status === 'FAILURE') {
                    clearInterval(intervalId);
                    if (data.result && data.result.status === 'Error') {
                        setError(data.result.message);
                    } else if (data.result && data.result.response) {
                        setResponse(data.result.response);
                    }
                    setTaskId(null);
                    setLoading(false);
                }
            } catch (error) {
                setError('Error fetching task status');
                setLoading(false);
            }
        };
    
        if (taskId) {
            intervalId = setInterval(checkTaskStatus, 5000); // Check elke 5 seconden
            return () => clearInterval(intervalId);
        }
    }, [taskId]);

    return { response, loading, error, chat };
};

export default useChatOpenCall;
