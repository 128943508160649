// src/hooks/useVoicesOpenCall.js
import { useState, useEffect } from 'react';
import axios from 'axios'; 
import config from '../config';

const useVoicesOpenCall = () => {
    const [voices, setVoices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchVoices = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get_voices_with_label/type/${config.SITE_NAME}`);
                // Filter en map direct de response naar de gewenste structuur.
                const filteredAndMappedVoices = response.data
                    .filter(voice => voice.labels && Object.values(voice.labels).includes('opencall'))
                    .map(voice => ({
                        id: voice.voice_id,
                        name: voice.name,
                        description: voice.description // Als voorbeeld, voeg alleen de relevante velden toe.
                    }));
                setVoices(filteredAndMappedVoices);
            } catch (error) {
                console.error('Error fetching voices:', error);
                setError('Er is een fout opgetreden bij het ophalen van de voices. Probeer het later opnieuw.');
            } finally {
                setLoading(false);
            }
        };

        fetchVoices();
    }, []);
    console.log("Voices:", voices); // Log de voices
    return { voices, loading, error };
};

export default useVoicesOpenCall;
