import React, { useState, useEffect, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { 
    Autocomplete, 
    TextField, 
    IconButton, 
    Box, 
    Button, 
    Container, 
    Paper, 
    Typography, 
    CircularProgress, 
    FormControl, 
    Modal, 
    Fade,
    Dialog,
    DialogTitle,
    DialogContent,
    Snackbar,
    Alert
} from '@mui/material';
import useVoices from '../../hooks/useVoices';
import useGenerateMultipleSpeeches from '../../hooks/useGenerateMultipleSpeeches';
import SpeechSentenceForm from '../SpeechSentenceForm/SpeechSentenceForm';
import useSpeechSentenceForm from '../../hooks/useSpeechSentenceForm';
import AudioSettingsForm from '../AudioSettingsForm/AudioSettingsForm';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import ChatForm from '../TheaterChatForm/ChatForm';
import useAudioFiles from '../../hooks/useAudioFiles';
import config from '../../config';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

function TheaterAudioForm() {
    const { voices, loading: voicesLoading, error: voicesError } = useVoices();
    const [selectedFormat, setSelectedFormat] = useState('');
    const { generateVoices, audioUrl, loading, error: generateError, taskStatus } = useGenerateMultipleSpeeches(); 
    const { sentences, setSentences, replaceSentences, addSentence, removeSentence, updateSentence } = useSpeechSentenceForm(voices, selectedFormat);
    const [isGenerating, setIsGenerating] = useState(false);
    const [hasGenerated, setHasGenerated] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const { audioFiles: bgVoices, loading: loadingBgVoices, error: bgVoicesError } = useAudioFiles(`${config.SITE_NAME}/bgvoice/`);

    const [audiosettings, setAudioSettings] = useState({
        intro: '',
        outro: '',
        bgaudio: ''
    });

    const [chatApiResponse, setChatApiResponse] = useState('');
    const [isApiResponseProcessed, setIsApiResponseProcessed] = useState(false);
    const [instructionsOpen, setInstructionsOpen] = useState(true);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (voicesError || generateError || bgVoicesError) {
            setOpenSnackbar(true);
        }
    }, [voicesError, generateError, bgVoicesError]);

    useEffect(() => {
        console.log('Current sentences state:', sentences);
    }, [sentences]);

    useEffect(() => {
        if (audioUrl) {
            setIsGenerating(false);
            setHasGenerated(true);
        }
    }, [audioUrl]);

    useEffect(() => {
        if (taskStatus === 'Task completed') {
            setIsGenerating(false);
            setHasGenerated(true);
        } else if (taskStatus === 'Task in progress') {
            setIsGenerating(true);
        }
    }, [taskStatus]);

    const onDragEnd = useCallback((result) => {
        if (!result.destination) return;
        const items = Array.from(sentences);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setSentences(items);
    }, [sentences, setSentences]);

    const handleSettingChange = (setting, value) => {
        setAudioSettings(prevSettings => ({
            ...prevSettings,
            [setting]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsGenerating(true);
        setHasGenerated(false);
        try {
            await generateVoices(sentences, audiosettings);
        } catch (err) {
            console.error('Error generating voices:', err);
            setIsGenerating(false);
            setOpenSnackbar(true);
        }
    };

    const handleChatApiResponse = async (response) => {
        console.log("API response received in AudioForm:", response);
        setChatApiResponse(response);

        if (!response || !response.script || isApiResponseProcessed) {
            console.error('chatApiResponse is null, heeft geen script, of is al verwerkt.');
            return;
        }

        const voiceNameToId = voices.reduce((acc, voice) => {
            acc[voice.name] = voice.voice_id;
            return acc;
        }, {});

        const newSentences = response.script.flatMap(segment => 
            segment.content.map(content => ({
                text: content.text, 
                voiceId: voiceNameToId[content.voice],
                bgVoiceId: '',
                audioFile: '',
                transformedUrl: ''
            }))
        );

        replaceSentences(newSentences);
        setIsApiResponseProcessed(true);
    };

    useEffect(() => {
        setIsApiResponseProcessed(false);
    }, [selectedFormat]);

    const handleInstructionsClose = () => setInstructionsOpen(false);

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpenSnackbar(false);
    };

    const handleCloseModal = () => setOpen(false);

    useEffect(() => {
        if (audioUrl) setOpen(true);
    }, [audioUrl]);

    const tags = voices.filter(voice => voice.labels && 'tag1' in voice.labels)
                       .flatMap(voice => Object.values(voice.labels));
    const uniqueTags = [...new Set(tags)];

    return (
        <Container className="container">
            {voicesLoading ? (
                <CircularProgress /> 
            ) : (
                <Paper className="paper">
                    <Snackbar
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackbar}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    >
                        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                            Er is een fout opgetreden: {voicesError || generateError || bgVoicesError}
                        </Alert>
                    </Snackbar>
                    
                    <Box marginBottom={2}>
                        <Button variant="outlined" color="primary" onClick={() => setInstructionsOpen(true)} size="large">
                            Instructies
                        </Button>
                        <Dialog open={instructionsOpen} onClose={handleInstructionsClose} aria-labelledby="instructions-dialog-title">
                            <DialogTitle id="instructions-dialog-title" className="dialogTitle">
                                <h3>Instructies</h3>
                                <IconButton edge="end" color="inherit" onClick={handleInstructionsClose} aria-label="close" style={{ position: 'absolute', right: '10px', top: '10px' }}>
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent className="dialogContent">
                                <ul>
                                    <li>Stap 1. Kies een format (of geen format en prompt zelf).</li>
                                    <li>Stap 2. Genereer een script over inhoud naar keuze.</li>
                                    <li>Stap 3. Bewerk het script of typ zelf een script.</li>
                                    <li>Stap 4. Kies record of upload.</li>
                                    <li>Stap 5. Record met je microfoon (of upload audiobestand).</li>
                                    <li>Stap 6. Kies een stem.</li>
                                    <li>Stap 7. Klik: transformeer naar gekozen stem.</li>
                                    <li>Stap 8. Optioneel: opnieuw.</li>
                                    <li>Stap 9. Optioneel: meerdere fragmenten.</li>
                                    <li>Stap 10. Genereer je Stemmy (alle fragmenten achter elkaar).</li>
                                    <li>Stap 11. Beluister en/of download je Stemmy.</li>
                                    <li>Stap 12. Maak nog een Stemmy.</li>
                                </ul>
                            </DialogContent>
                        </Dialog>
                    </Box>

                    <Box className="typographyHeader">
                        <Typography variant="h4">
                            Genereer Speech to Speech met Stemmy
                        </Typography>
                    </Box>

                    <Box className="formatSelect" marginBottom={2}>
                        <FormControl fullWidth>
                            <Autocomplete
                                id="format-select"
                                options={uniqueTags}
                                getOptionLabel={(option) => option}
                                value={selectedFormat}
                                onChange={(event, newValue) => setSelectedFormat(newValue)}
                                renderInput={(params) => <TextField {...params} label="Kies een Format" />}
                            />
                        </FormControl>
                    </Box>

                    <ChatForm 
                        onChatResponse={handleChatApiResponse} 
                        chatApiResponse={chatApiResponse}
                        selectedFormat={selectedFormat} 
                    />

                    <form onSubmit={handleSubmit}>
                        <AudioSettingsForm 
                            audiosettings={audiosettings} 
                            setAudioSettings={setAudioSettings} 
                            handleSettingChange={handleSettingChange}
                            tags={uniqueTags}
                            selectedFormat={selectedFormat}
                            setSelectedFormat={setSelectedFormat}
                        />

                        <Box className="typographyHeader">
                            <Typography variant="h5">
                                Spreek een fragment in (of upload) en transformeer de stem                   
                            </Typography>
                            <Typography>
                                Pas als alle stemmen getransformeerd zijn, kan je een Stemmy genereren.<br /> 
                                Een Stemmy plakt alle fragmenten achter elkaar en voegt eventueel een intro, outro en achtergrond toe.                
                            </Typography>
                        </Box>

                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="dropsentences">
                                {(provided) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                        {sentences.map((sentence, index) => (
                                            <Draggable key={`sentence-${index}`} draggableId={`sentence-${index}`} index={index}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        className="draggableContainer"
                                                    >
                                                        <div {...provided.dragHandleProps} className="dragHandleContainer">
                                                            <DragHandleIcon />
                                                        </div>
                                                        <SpeechSentenceForm
                                                            index={index}
                                                            sentence={sentence}
                                                            voices={voices.filter(voice => {
                                                                if (selectedFormat === '') return true;
                                                                if (!voice.labels) return false;
                                                                return Object.entries(voice.labels).some(([key, value]) => key.startsWith('tag') && value === selectedFormat);
                                                            })}
                                                            onSentenceChange={updateSentence}
                                                            onRemoveSentence={removeSentence}
                                                            selectedFormat={selectedFormat}
                                                            bgVoices={bgVoices.filter(bgVoice => selectedFormat === '' || bgVoice.filename.startsWith(`${selectedFormat}_`))}
                                                            loadingBgVoices={loadingBgVoices}
                                                        />      
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>

                        <div className="buttonGroup" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Button 
                                variant="contained" 
                                color="secondary" 
                                onClick={addSentence} 
                                endIcon={<AddIcon />}
                                disabled={loading}
                                className="addButton"
                                style={{ marginBottom: '10px' }} // voeg wat ruimte toe tussen de knoppen
                            >
                                Extra fragment
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                endIcon={<SendIcon />}
                                disabled={
                                    loading || 
                                    isGenerating || 
                                    !sentences.every(sentence => sentence.transformedUrl.trim() !== "")
                                }
                                className="submitButton"
                                style={{ fontSize: '20px', padding: '10px 20px' }} // maak deze knop groter
                            >
                                {loading || isGenerating ? (
                                    <>
                                        <CircularProgress size={24} className="addRemoveButton" />
                                        {taskStatus === 'Task in progress' ? 'Stemmy aan het maken (dit kan een minuutje duren)' : 'Taak wordt verwerkt...'}
                                    </>
                                ) : hasGenerated ? 'Genereer nog een Stemmy' : 'Klaar? Stemmy maken'}
                            </Button>
                        </div>

                        <div>
                            {sentences.map((sentence, index) => (
                                <div key={index}>
                                    <p>Sentence {index + 1}</p>
                                    <pre>{JSON.stringify(sentence, null, 2)}</pre>
                                </div>
                            ))}
                        </div>
                        
                        <div>
                            {audioUrl && (
                                <Modal
                                    open={open}
                                    onClose={handleCloseModal}
                                    closeAfterTransition
                                >
                                    <Fade in={open}>
                                        <Box 
                                            display="flex" 
                                            flexDirection="column" 
                                            alignItems="center" 
                                            justifyContent="center" 
                                            sx={{ 
                                                position: 'absolute', 
                                                top: '50%', 
                                                left: '50%', 
                                                transform: 'translate(-50%, -50%)', 
                                                bgcolor: 'background.paper', 
                                                boxShadow: 24, 
                                                p: 4,
                                                backgroundColor: '#fff',
                                                borderRadius: '10px'
                                            }}
                                        >
                                            <IconButton 
                                                style={{ position: 'absolute', right: 0, top: 0 }} 
                                                onClick={handleCloseModal}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                            <Typography 
                                                variant="h4" 
                                                className='typographyHeader'
                                                style={{ padding: '10px' }}
                                            >
                                                Stemmy gereed!
                                            </Typography>
                                            {audioUrl.endsWith('.mp4') ? (
                                                <video controls src={audioUrl} />
                                            ) : (
                                                <AudioPlayer
                                                    autoPlay
                                                    src={audioUrl}
                                                    onPlay={e => console.log("onPlay")}
                                                />
                                            )}
                                            <a href={audioUrl} download>
                                                <Button variant="contained" color="primary" style={{ marginTop: '15px' }}>
                                                    Download Stemmy
                                                </Button>
                                            </a>
                                        </Box>
                                    </Fade>
                                </Modal>
                            )}
                            {generateError && <div>Error: {generateError}</div>}
                        </div>
                    </form>
                </Paper>
            )}
        </Container>
    );
}

export default TheaterAudioForm;
