import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, CircularProgress, Typography } from '@mui/material';
import useChatOpenCall from '../../hooks/useChatOpenCall';
import useGenerateSpokenFeedback from '../../hooks/useGenerateSpokenFeedback';

function SectionForm({ label, value, onChange, selectedVoices }) {
    const [input, setInput] = useState(value);
    const { response, chat, loading: chatLoading, error: chatError } = useChatOpenCall();
    const { audioUrl, generateVoices, loading: voicesLoading, error: voicesError, taskStatus, taskId } = useGenerateSpokenFeedback();

    useEffect(() => {
        if (response && !chatLoading && !voicesLoading && !taskId) { // Toegevoegd !taskId om te voorkomen dat taken dubbel worden gestart
            const textVoicePairs = response.script.flatMap(section => 
                section.content.map(content => ({
                    text: content.text,
                    voiceId: selectedVoices.find(voice => voice.name === content.voice)?.id || '',
                    bgVoice: null,
                    lalaId: null,
                }))
            );

            if (textVoicePairs.length > 0) {
                generateVoices(textVoicePairs, {
                    intro: null,
                    outro: null,
                    bgaudio: null,
                    format: 'opencall',
                });
            }
        }
    }, [response, chatLoading, voicesLoading, taskId]); // Toegevoegd taskId aan de dependencies

    const handleInputChange = (event) => setInput(event.target.value);

    const handleFullWorkflow = async () => {
        if (!chatLoading && !voicesLoading && !taskId) {  // Toegevoegd check op taskId
            try {
                const shuffledVoices = [...selectedVoices].sort(() => Math.random() - 0.5);
                const randomVoiceNames = shuffledVoices.slice(0, 3).map(voice => voice.name);
                await chat(input, randomVoiceNames);
            } catch (error) {
                console.error("Error during chat call:", error);
            }
        }
    };

    return (
        <Box component="form" onSubmit={(e) => e.preventDefault()}>
            <TextField
                label={label}
                fullWidth
                multiline
                value={input}
                onChange={handleInputChange}
                margin="normal"
                placeholder="Zet hier je tekst neer."
                rows={4}
            />
            {chatError && <Typography color="error">Error: {chatError}</Typography>}
            {voicesError && <Typography color="error">Error: {voicesError}</Typography>}
            <Button
                onClick={handleFullWorkflow}
                variant="contained"
                color="secondary"
                disabled={chatLoading || voicesLoading || taskId != null}
            >
                {chatLoading ? 'Script wordt gegenereerd...' :
                voicesLoading ? 'Script wordt ingesproken...' :
                'Laat de focusgroep reageren'}
                {chatLoading || voicesLoading ? <CircularProgress size={24} /> : null}
            </Button>
            {audioUrl && (
                <audio controls>
                    <source src={audioUrl} type="audio/mpeg" />
                    Your browser does not support the audio element.
                </audio>
            )}
        </Box>
    );
}

export default React.memo(SectionForm);
