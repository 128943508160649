// src/hooks/useSentenceForm.js
import { useState, useEffect } from 'react';

const useSpeechSentenceForm = (initialVoices, selectedFormat) => {
    const initialSentence = { 
        text: '', 
        voiceId: initialVoices.length > 0 ? initialVoices[Math.floor(Math.random() * initialVoices.length)].voice_id : '',
        transformedUrl: ''
     };
    const [sentences, setSentences] = useState([initialSentence]);

    useEffect(() => {
        if (initialVoices.length > 0) {
            setSentences(currentSentences => {
                const newSentences = [...currentSentences];
                newSentences.forEach((sentence, index) => {
                    const filteredVoices = selectedFormat ? initialVoices.filter(voice => voice.labels && voice.labels['tag1'] === selectedFormat) : initialVoices;
                    const randomVoiceId = filteredVoices.length > 0 ? filteredVoices[Math.floor(Math.random() * filteredVoices.length)].voice_id : '';
                    newSentences[index]['voiceId'] = randomVoiceId;
                });
                return newSentences;
            });
        }
    }, [initialVoices, selectedFormat]);

    const addSentence = () => {
        const randomVoiceId = initialVoices.length > 0 ? initialVoices[Math.floor(Math.random() * initialVoices.length)].voice_id : '';
        setSentences(currentSentences => [...currentSentences, { text: '', voiceId: randomVoiceId, transformedUrl: '' }]);
    };

    const removeSentence = (index) => {
        setSentences(currentSentences => currentSentences.filter((_, i) => i !== index));
    };

    const updateSentence = (index, key, value) => {
        setSentences(currentSentences => {
            const newSentences = [...currentSentences];
            newSentences[index][key] = value;
    
            // If the format changes, select a new random voice
            if (key === 'format') {
                const filteredVoices = initialVoices.filter(voice => voice.labels && voice.labels['tag1'] === value);
                const randomVoiceId = filteredVoices.length > 0 ? filteredVoices[Math.floor(Math.random() * filteredVoices.length)].voice_id : '';
                newSentences[index]['voiceId'] = randomVoiceId;
            }
    
            return newSentences;
        });
    };

    const replaceSentences = (newSentences) => {
        setSentences(newSentences);
    };

    return {
        sentences,
        setSentences,
        addSentence,
        removeSentence,
        updateSentence,
        replaceSentences
    };
};

export default useSpeechSentenceForm;