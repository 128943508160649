// src/components/SpeechSentenceForm/SpeechSentenceForm.js
import React from 'react';
import {useState , useEffect, useCallback} from 'react';
import { Box, Typography, Autocomplete, Button, TextField, CircularProgress, IconButton, InputAdornment, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import config from '../../config';
import AudioStream from '../AudioStream/AudioStream'
//import SpeechStream from '../SpeechStream/SpeechStream';
// import AudioRecorder from '../AudioRecorder/AudioRecorder';
import SpeechTransformer from '../SpeechTransformer/SpeechTransformer';
import useGenerateSpeechToSpeech from '../../hooks/useGenerateSpeechToSpeech'; 

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import SendIcon from '@mui/icons-material/Send';


const SpeechSentenceForm = ({ index, sentence, voices, bgVoices, loadingBgVoices, onSentenceChange, onRemoveSentence, selectedFormat }) => {    
    
    const { text, voiceId, audioFile, transformedUrl } = sentence;

    
    const { generateSpeech, speechUrl, loading: speechLoading, error: speechError, taskStatus: speechTaskStatus } = useGenerateSpeechToSpeech();
    
    const [audioFiles, setAudioFiles] = useState([]);

    const [selectedAudioFile, setSelectedAudioFile] = useState(null);

    const handleTransformedUrlChange = useCallback((newUrl) => {
        onSentenceChange(index, 'transformedUrl', newUrl);
    }, [index, onSentenceChange]);

    const handleGenerateSpeech = async () => {
        if (selectedAudioFile) {
            await generateSpeech({ audioContent: selectedAudioFile });
        }
    };
    
   
    const voiceSettings = {
        stability: 0.3,
        clarity: 0.98,
      };
    
    const bgVoiceFolder = `${config.SITE_NAME}/bgvoice/`;
    const bgVoiceId = sentence.bgVoiceId || '';
    const faceId = sentence.faceId || '';

    const [hasSelectedVoice, setHasSelectedVoice] = useState(false);
    const [hasSelectedBgVoice, setHasSelectedBgVoice] = useState(false);
    const [hasSelectedFace, setHasSelectedFace] = useState(false);
    const [filteredBgVoices, setFilteredBgVoices] = useState([]);

    const handleBgVoiceChange = useCallback((index, filename) => {
        onSentenceChange(index, 'bgVoiceId', filename);
    }, [onSentenceChange]);

    const handleFaceChange = useCallback((index, url) => {
        onSentenceChange(index, 'faceId', url);
    }, [onSentenceChange]);

    useEffect(() => {
        console.log(`Audio file updated in sentence ${index}: `, audioFile);
    }, [audioFile, index]);


    useEffect(() => {
        if (selectedFormat) {
            const newFilteredBgVoices = bgVoices.filter(file => file.filename.startsWith(`${selectedFormat}_`));
            setFilteredBgVoices(newFilteredBgVoices);
    
            if (!hasSelectedBgVoice) {
                handleBgVoiceChange(index, '');
                setHasSelectedBgVoice(true);
            }
        } else {
            setFilteredBgVoices(bgVoices);
    
            if (!hasSelectedBgVoice) {
                handleBgVoiceChange(index, '');
                setHasSelectedBgVoice(true);
            }
        }
    }, [selectedFormat, bgVoices, hasSelectedBgVoice, handleBgVoiceChange, index]);

    useEffect(() => {
        if (!hasSelectedVoice && voices.length > 0 && !sentence.voiceId) {
            const newVoice = voices[Math.floor(Math.random() * voices.length)];
            onSentenceChange(index, 'voiceId', newVoice.voice_id);
            setHasSelectedVoice(true);
        }
    }, [voices, sentence.voiceId, selectedFormat, filteredBgVoices, hasSelectedVoice, onSentenceChange, index]);
    

    
    useEffect(() => {
        if (process.env.REACT_APP_VIDEO_ACCESS === 'yes') {
            if (!hasSelectedFace && faceId) {
                handleFaceChange(index, faceId);
                setHasSelectedFace(true);
            }
        }
    }, [faceId, hasSelectedFace, handleFaceChange, index]);

    
    return (
        <div className="sentenceForm">
                <div className="flexContainer">

        <div className="flexContainer" style={{ display: 'flex', flexDirection: 'column' }}>
            <Box mb={2} className="typographyHeader">
                <Typography variant="h5" style={{ color: transformedUrl ? 'green' : 'initial', marginBottom: 0 }}>                        
                    Fragment {index + 1} <IconButton onClick={() => onRemoveSentence(index)}>
                        <DeleteIcon />
                    </IconButton>
                </Typography>
                <Typography variant="p">
                    Let op: Je zit in speech-to-speech modus: het tekstveld is alleen bedoeld als script om voor te dragen.
                </Typography>

            </Box>


                <Box mb={2} className="textField">
                    <TextField
                    label={`Fragment ${index + 1}`}
                    multiline
                    rows={5}
                    fullWidth
                    placeholder="Elk 'fragment' (of spreekbeurt) is gekoppeld aan één stem.
                   Let op: Je zit in speech-to-speech modus: de tekst in dit veld is alleen bedoeld als script om voor te dragen."
                    variant="outlined"
                    value={sentence.text}
                    onChange={(e) => onSentenceChange(index, 'text', e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => onRemoveSentence(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                /> 
               
               

                <SpeechTransformer 
                    setAudioFile={(audioFile) => onSentenceChange(index, 'audioFile', audioFile)} 
                    onTransformedUrlChange={(newUrl) => handleTransformedUrlChange(newUrl)}
                    voiceId={sentence.voiceId}
                    voiceSettings={{ stability: 0.3, clarity: 0.98 }}
                />

                {speechUrl && (
                    <Box mt={2}>
                        <AudioPlayer
                            autoPlay
                            src={speechUrl}
                            onPlay={e => console.log("onPlay")}
                        />
                    </Box>
                )}

                </Box>

        </div>

                <div className="flexItem">
                    <div className="formControls">
                        {/* Voice Select */}

                        <Box mb={2} className="formControl">
                            <FormControl fullWidth>
                                <Autocomplete
                                    id={`voice-select-${index}`}
                                    options={voices}
                                    getOptionLabel={(option) => option.name}
                                    value={voices.find(voice => voice.voice_id === sentence.voiceId) || null}
                                    onChange={(event, newValue) => {
                                        onSentenceChange(index, 'voiceId', newValue ? newValue.voice_id : '');
                                    }}
                                    isOptionEqualToValue={(option, value) => option.voice_id === value.voice_id}
                                    renderInput={(params) => <TextField {...params} label="Stem" />}
                                />
                            </FormControl>
                        </Box>
                        {/* BgVoice Select */}
                        <Box mb={2} className="formControl">
                            <FormControl fullWidth>
                                <InputLabel id={`bgvoice-select-label-${index}`}>Achtergrondgeluid</InputLabel>
                                <Select
                                    labelId={`bgvoice-select-label-${index}`}
                                    id={`bgvoice-select-${index}`}
                                   // Only show filename value={bgVoiceId ? bgVoiceId.replace(bgVoiceFolder, '') : ''} 
                                   value={bgVoiceId}                                    
                                   label="Achtergrondgeluid"
                                    onChange={(e) => handleBgVoiceChange(index, e.target.value)}
                                    disabled={loadingBgVoices} // Disable select while loading
                                >
                                <MenuItem value="">Geen</MenuItem>
                                {loadingBgVoices ? (
                                    <MenuItem value="">
                                        <em>Loading...</em>
                                    </MenuItem>
                                ) : (
                                bgVoices.map((bgVoice, idx) => (
                                    //<MenuItem key={idx} value={bgVoice.filename}>{bgVoice.filename.replace('.mp3', '')}</MenuItem>
                                    <MenuItem key={idx} value={bgVoiceFolder + bgVoice.filename}>{bgVoice.filename.replace('.mp3', '')}</MenuItem>
                                ))
                                )}
                                </Select>
                            </FormControl>
                        </Box>

                    {/* Face Select and venv */}
                    {process.env.REACT_APP_VIDEO_ACCESS === 'yes' && (
                        <Box mb={2} className="formControl">
                            <FormControl fullWidth>
                                <InputLabel id={`face-select-label-${index}`}>Praatgezicht</InputLabel>
                                <Select
                                    labelId={`face-select-label-${index}`}
                                    id={`face-select-${index}`}
                                    value={faceId}
                                    label="Praatgezicht"
                                    onChange={(e) => handleFaceChange(index, e.target.value)}
                                >
                                    <MenuItem value="">Geen</MenuItem>
                                    <MenuItem value="voxpop_man_30_china_2">voxpop_man_30_china_2</MenuItem>
                                    <MenuItem value="voxpop_man_30_china_3">voxpop_man_30_china_3</MenuItem>
                                    <MenuItem value="voxpop_man_30_china">voxpop_man_30_china</MenuItem>
                                    <MenuItem value="voxpop_man_30_nl_2">voxpop_man_30_nl_2</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    )}
                    </div>
                </div>
            </div>

            

        </div>
    );
};

export default SpeechSentenceForm;
