import React, {useState, useEffect} from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { 
    Autocomplete, 
    TextField, 
    IconButton, 
    Box, 
    Button, 
    Container, 
    Paper, 
    Typography, 
    CircularProgress, 
    FormControl, 
    Modal, 
    Fade,
    Dialog,
    DialogTitle,
    DialogContent,
} from '@mui/material';
import useVoices from '../../hooks/useVoices';
import useGenerateMultipleVoices from '../../hooks/useGenerateMultipleVoices';
import SentenceForm from '../SentenceForm/SentenceForm';
import useSentenceForm from '../../hooks/useSentenceForm';
import AudioSettingsForm from '../AudioSettingsForm/AudioSettingsForm';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import ChatForm from '../ChatForm/ChatForm';
import useAudioFiles from '../../hooks/useAudioFiles';
import config from '../../config';

import CloseIcon from '@mui/icons-material/Close';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';


function AudioForm() {
    const { voices, loading: voicesLoading } = useVoices();

   // const [selectedFormat, setSelectedFormat] = useState('');
   // const tags = [...new Set(voices.flatMap(voice => voice.labels ? Object.values(voice.labels) : []))];
    const tags = [...new Set(voices
    .filter(voice => voice.labels && 'tag1' in voice.labels)
    .flatMap(voice => Object.values(voice.labels))
    )];
    
    const [selectedFormat, setSelectedFormat] = useState('');
    const { generateVoices, audioUrl, loading, error, taskStatus } = useGenerateMultipleVoices(); 
    const { sentences, setSentences, replaceSentences, addSentence, removeSentence, updateSentence} = useSentenceForm(voices, selectedFormat);
    const [isGenerating, setIsGenerating] = useState(false);
    const [hasGenerated, setHasGenerated] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const { audioFiles: bgVoices, loading: loadingBgVoices } = useAudioFiles(`${config.SITE_NAME}/bgvoice/`);

    const [audiosettings, setAudioSettings] = useState({
        intro: '',
        outro: '',
        bgaudio: ''
    });

    // Drag and drop
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const items = Array.from(sentences);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setSentences(items);
    };

    const handleSettingChange = (setting, value) => {
        setAudioSettings(prevSettings => ({
            ...prevSettings,
            [setting]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsGenerating(true);
        setHasGenerated(false);
        await generateVoices(sentences, audiosettings);
        // Error handling verplaatst naar useEffect
    };

    useEffect(() => {
        if (audioUrl) {
            setIsGenerating(false);
            setHasGenerated(true);
        }
    }, [audioUrl]);

    useEffect(() => {
        if (error) {
            setIsGenerating(false);
            setHasGenerated(false);
            setOpenSnackbar(true); // Open the Snackbar
        }
    }, [error]);

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    useEffect(() => {
        if (taskStatus === 'Task completed') {
            setIsGenerating(false);
            setHasGenerated(true);
        } else if (taskStatus === 'Task in progress') {
            setIsGenerating(true);
        }
    }, [taskStatus]);

    const [chatApiResponse, setChatApiResponse] = useState('');
    const [isApiResponseProcessed, setIsApiResponseProcessed] = useState(false);

    const handleChatApiResponse = (response) => {
        console.log("API response received in AudioForm:", response); // Voor debugging
        setChatApiResponse(response); // Direct instellen van response
    
        if (!response || !response.script || isApiResponseProcessed) {
            console.error('chatApiResponse is null, heeft geen script, of is al verwerkt.');
            return;
        }
    
        // Voortzetten zoals eerder
        const voiceNameToId = voices.reduce((acc, voice) => {
            acc[voice.name] = voice.voice_id;
            return acc;
        }, {});
    
        const newSentences = response.script.flatMap(segment => 
            segment.content.map(content => {
                return {
                    text: content.text, 
                    voiceId: voiceNameToId[content.voice],
                    bgVoiceId: ''
                }
            })
        );
    
        replaceSentences(newSentences);
        setIsApiResponseProcessed(true); // Markeer de response als verwerkt
    };

    useEffect(() => {
        // Reset isApiResponseProcessed wanneer nodig, bijvoorbeeld:
        setIsApiResponseProcessed(false);
    }, [selectedFormat, /* andere relevante dependencies */ ]);

    const [open, setOpen] = useState(false);

    useEffect(() => {
      if (audioUrl) {
        setOpen(true);
      }
    }, [audioUrl]);
  
    const handleClose = () => {
      setOpen(false);
    };

    const [instructionsOpen, setInstructionsOpen] = React.useState(true);

    const handleInstructionsOpen = () => {
    setInstructionsOpen(true);
    };

    const handleInstructionsClose = () => {
    setInstructionsOpen(false);
    };

    
    
    return (
        <Container className="container">
            {voicesLoading ? (
                <CircularProgress /> 
            ) : (
            <Paper className="paper">
                <Box marginBottom={2}>
                    <Button variant="outlined" color="primary" onClick={handleInstructionsOpen} size="large">
                        Instructies
                    </Button>
                    <Dialog open={instructionsOpen} onClose={handleInstructionsClose} aria-labelledby="instructions-dialog-title">
                        <DialogTitle id="instructions-dialog-title" className="dialogTitle">
                            <h3>Instructies</h3>
                            <IconButton edge="end" color="inherit" onClick={handleInstructionsClose} aria-label="close" style={{ position: 'absolute', right: '10px', top: '10px' }}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>

                        <DialogContent className="dialogContent">
                                <ul>
                                    <li>Stap 1. Kies een format (bijv Tel sell).</li>
                                    <li>Stap 2. Typ of copy/paste dingen die je in het script terug wil zien.</li>
                                    <li>Stap 3. Klik 'maak er een script van'.</li>
                                </ul>
                                <ul>
                                    <li>Stap 4. Optioneel: verander intro/outro en/of achtergrondmuziek.</li>
                                    <li>Stap 5. Optioneel: verander de stem bij fragment.</li>
                                    <li>Stap 6. Optioneel: verander achtergrondgeluid bij de fragmenten.</li>
                                    <li>Stap 7. Optioneel: verander de tekst of voeg extra fragmenten toe.</li>
                                </ul>
                                <ul>
                                    <li>Stap 8. Genereer je Stemmy.</li>
                                    <li>Stap 9. Beluister en/of download je Stemmy.</li>
                                    <li>Stap 10. Maak nog een Stemmy.</li>
                                </ul>
                        </DialogContent>
                    </Dialog>
                </Box>

                <Box className="typographyHeader">
                    <Typography variant="h4">
                        Ontwerp een auditieve ervaring met Stemmy
                    </Typography>
                </Box>
                
                {/* Dropdown-menu voor het kiezen van een format */}
                <Box className="formatSelect" marginBottom={2} >
                    <FormControl fullWidth>
                        <Autocomplete
                            id="format-select"
                            options={tags}
                            getOptionLabel={(option) => option}
                            value={selectedFormat}
                            onChange={(event, newValue) => {
                                setSelectedFormat(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} label="Kies een Format" />}
                        />
                    </FormControl>
                </Box>

                <ChatForm 
                    onChatResponse={handleChatApiResponse} 
                    chatApiResponse={chatApiResponse}
                    selectedFormat={selectedFormat} 
                />

                <form onSubmit={handleSubmit}>
                
                    <AudioSettingsForm 
                        audiosettings={audiosettings} 
                        setAudioSettings={setAudioSettings} 
                        handleSettingChange={handleSettingChange}
                        tags={tags}
                        selectedFormat={selectedFormat}
                        setSelectedFormat={setSelectedFormat}
                    />

                    <Box className="typographyHeader">
                        <Typography variant="h5" className="typographyHeader">
                            Elk fragment is gekoppeld aan een stem
                        </Typography>
                    </Box>

                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="dropsentences">
                            {(provided) => (
                                <div 
                                    {...provided.droppableProps} 
                                    ref={provided.innerRef}
                                >
                                    {sentences.map((sentence, index) => (
                                        <Draggable 
                                            key={`sentence-${index}`} 
                                            draggableId={`sentence-${index}`} 
                                            index={index}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    className="draggableContainer"
                                                >
                                                    <div {...provided.dragHandleProps} 
                                                        className="dragHandleContainer">
                                                        <DragHandleIcon />
                                                    </div>
                                                   
                                                    <SentenceForm
                                                        index={index}
                                                        sentence={sentence}
                                                        voices={voices.filter(voice => {
                                                            if (selectedFormat === '') return true;
                                                            if (!voice.labels) return false;
                                                            return Object.entries(voice.labels).some(([key, value]) => key.startsWith('tag') && value === selectedFormat);
                                                        })}
                                                        onSentenceChange={updateSentence}
                                                        onRemoveSentence={removeSentence}
                                                        selectedFormat={selectedFormat}
                                                        bgVoices={bgVoices.filter(bgVoice => selectedFormat === '' || bgVoice.filename.startsWith(`${selectedFormat}_`))}
                                                        loadingBgVoices={loadingBgVoices}
                                                        faceId={sentence.faceId}
                                                    />      
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>

                   

                    <div className="buttonGroup">
                        <Button 
                            variant="contained" 
                            color="secondary" 
                            onClick={addSentence} 
                            disabled={loading}
                            className="addButton"
                        >
                            Extra zin/fragment toevoegen
                        </Button>
                        <Box mr={2} />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={
                                loading || 
                                isGenerating
                            }
                            className="submitButton"
                        >
                            {loading || isGenerating ? (
                                <>
                                    <CircularProgress size={24} className="addRemoveButton" />
                                    {taskStatus === 'Task in progress' ? 'Stemmy aan het maken (dit kan een minuutje duren)' : 'Taak wordt verwerkt...'}
                                </>
                            ) : hasGenerated ? 'Genereer nog een Stemmy' : 'Klaar? Genereer Stemmy'}
                        </Button>

                        
                    </div>

                    <div>
                        {audioUrl && (
                            <div className="audioSection">
                                
                                <Modal
                                    open={open}
                                    onClose={handleClose}
                                    closeAfterTransition
                                >
                                    <Fade in={open}>
                        
                                        
                                    <Box 
                                        display="flex" 
                                        flexDirection="column" 
                                        alignItems="center" 
                                        justifyContent="center" 
                                        sx={{ 
                                            position: 'absolute', 
                                            top: { xs: '10%', md: '50%' }, 
                                            left: { xs: '10%', md: '50%' }, 
                                            transform: { md: 'translate(-50%, -50%)' }, 
                                            bgcolor: 'background.paper', 
                                            boxShadow: 24, 
                                            p: 4,
                                            backgroundColor: '#fff',
                                            borderRadius: '10px',
                                            width: { xs: 'auto', md: 'auto' },
                                            height: { xs: 'auto', md: 'auto' }
                                        }}
                                    >
                                        <IconButton 
                                            style={{ position: 'absolute', right: 0, top: 0 }} 
                                            onClick={handleClose}
                                        >
                                            <CloseIcon />
                                        </IconButton>

                                        <Typography 
                                            variant="h4" 
                                            className='typographyHeader'
                                            style={{ padding: '10px' }}
                                        >
                                            Stemmy gereed!
                                        </Typography>

                                        {audioUrl.endsWith('.mp4') ? (
                                            <video controls src={audioUrl} />
                                        ) : (
                                            <AudioPlayer
                                                autoPlay
                                                src={audioUrl}
                                                onPlay={e => console.log("onPlay")}
                                                // other props here
                                            />
                                        )}

                                        <a href={audioUrl} download>
                                            <Button variant="contained" color="primary" style={{ marginTop: '15px' }}>
                                                Download Stemmy
                                            </Button>
                                        </a>

                                    </Box>
                                    </Fade>
                                </Modal>
                            </div>
                        )}
                        {error && <div>Error: {error}</div>}
                    </div>
                    
                </form>
                </Paper>
        )}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                    Er is een fout opgetreden: {error}
                </Alert>
            </Snackbar>
        </Container>

        
    );
}

export default AudioForm;
