import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, CircularProgress, Typography } from '@mui/material';
import useChatFeedback from '../../hooks/useChatFeedback';
import useGenerateSpokenFeedback from '../../hooks/useGenerateSpokenFeedback';

function FeedbackSectionForm({ label, value, onChange, selectedVoices, selectedFormat, selectedTone }) {
    const [input, setInput] = useState(value);
    const { response, chat, loading: chatLoading, error: chatError } = useChatFeedback();
    const { audioUrl, generateVoices, loading: voicesLoading, error: voicesError, taskStatus, taskId } = useGenerateSpokenFeedback();

    useEffect(() => {

        console.log("Starting useEffect with response:", response);
        if (!response || !response.script) {
        console.error("Invalid or missing script in the response");
        return;
    }
        const textVoicePairs = response?.script?.flatMap(section => 
            section.content.map(content => ({
                text: content.text,
                voiceId: selectedVoices.find(voice => voice.name === content.voice)?.id || '',
                bgVoice: null,
                lalaId: null,
            }))
        ) || [];

        console.log("Generated textVoicePairs:", textVoicePairs);
    
        if (textVoicePairs.length > 0 && !chatLoading && !voicesLoading && !taskId) {
            try {
                generateVoices(textVoicePairs, {
                    intro: null,
                    outro: null,
                    bgaudio: null,
                    format: selectedFormat,
                });
            } catch (error) {
                console.error("Error during generateVoices call:", error);
            }
        } else {
            console.log("Conditions not met for generateVoices. Length:", textVoicePairs.length, "Loading/chat/task states:", chatLoading, voicesLoading, taskId);
        }
    }, [response, chatLoading, voicesLoading, taskId, selectedVoices, generateVoices, selectedFormat]);
    

    const handleInputChange = (event) => setInput(event.target.value);

    const handleFullWorkflow = async () => {
        if (!chatLoading && !voicesLoading && !taskId) {  
            try {
                const shuffledVoices = [...selectedVoices].sort(() => Math.random() - 0.5);
                const randomVoiceNames = shuffledVoices.slice(0, 3).map(voice => voice.name);
                await chat(input, randomVoiceNames, selectedFormat, selectedTone);
            } catch (error) {
                console.error("Error during chat call:", error);
            }
        }
    };

    return (
        <Box component="form" onSubmit={(e) => e.preventDefault()}>
            <TextField
                label={label}
                fullWidth
                multiline
                value={input}
                onChange={handleInputChange}
                margin="normal"
                placeholder="Put your text here."
                rows={4}
            />
            {chatError && <Typography color="error">Error: {chatError}</Typography>}
            {voicesError && <Typography color="error">Error: {voicesError}</Typography>}
            <Button
                onClick={handleFullWorkflow}
                variant="contained"
                color="secondary"
                disabled={chatLoading || voicesLoading || taskId != null}
            >
                {chatLoading ? 'Script is being generated...' :
                voicesLoading ? 'Script is being spoken...' :
                'Let the AI speak!'}
                {chatLoading || voicesLoading ? <CircularProgress size={24} /> : null}
            </Button>
            {audioUrl && (
                <audio controls>
                    <source src={audioUrl} type="audio/mpeg" />
                    Your browser does not support the audio element.
                </audio>
            )}
        </Box>
    );
}

export default React.memo(FeedbackSectionForm);
