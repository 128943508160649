// src/hooks/useAudioFiles.js
import { useState, useEffect } from 'react';
import axios from 'axios'; // importeer axios

const useAudioFiles = (folder) => {
    const [audioFiles, setAudioFiles] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAudioFiles = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/media?folder=${folder}`);
                setAudioFiles(response.data.files);
            } catch (error) {
                console.error('Error loading audio files:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchAudioFiles();
    }, [folder]);

    return { audioFiles, loading };
};

export default useAudioFiles;

// const response = await axios.get(`https://surrounded-3fe36f6a1847.herokuapp.com/api/media?folder=${folder}`);