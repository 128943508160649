import { useState, useEffect } from 'react';
import axios from 'axios';

const useChat = (input, selectedFormat) => {
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [taskId, setTaskId] = useState(null);
    const [taskStatus, setTaskStatus] = useState(null);

    const chat = async (userInput, selectedFormat) => {
        setLoading(true);
        setError(null);
        setResponse(null);
        setTaskStatus('Task in progress');
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/chat`, 
                {
                    input: userInput,
                    format: selectedFormat
                },
                { headers: { 'Content-Type': 'application/json' } }
            );
            setTaskId(response.data.task_id);
        } catch (error) {
            setError(error.response?.data?.message || error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        let intervalId;
    
        const checkTaskStatus = async () => {
            if (taskId) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/task-status/${taskId}`);
                    const data = response.data;
    
                    if (data.status === 'Task completed') {
                        clearInterval(intervalId);
                        if (data.result && data.result.status === 'Error') {
                            setError(data.result.message || 'Er is een fout opgetreden bij het verwerken van de taak');
                        } else if (data.result && data.result.response && data.result.response.response && data.result.response.response.script) {
                            const script = data.result.response.response.script;
                            const messages = script.flatMap(segment => segment.content.map(content => ({ text: content.text, voice: content.voice })));
                            setResponse({ messages, script });
                        } else {
                            setError('Onverwachte responsstructuur van API');
                        }
                        setTaskId(null);
                        setTaskStatus(data.status);
                    } else {
                        setTaskStatus(data.status);
                    }
                } catch (error) {
                    clearInterval(intervalId);
                    setError(error.response?.data?.message || 'Fout bij het ophalen van de taakstatus');
                    setTaskId(null);
                } finally {
                    setLoading(false);
                }
            }
        };
    
        if (taskId) {
            intervalId = setInterval(checkTaskStatus, 5000); // Check elke 5 seconden
        }
    
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [taskId, input, selectedFormat]); 

    return { response, loading, error, chat, taskStatus, taskId };
};

export default useChat;
