import { useState, useEffect } from 'react';
import { TextField, Button, Box, CircularProgress } from '@mui/material';
import useChat from '../../hooks/useChat';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import useSentimentAnalysis from '../../hooks/useSentimentAnalysis'; 

const ChatForm = ({ onChatResponse, selectedFormat }) => {
    const [input, setInput] = useState('');
    const { response, chat, loading, error: chatError, taskStatus, taskId } = useChat();
    const { emotions, analyzeSentiment, loading: sentimentLoading, error: sentimentError } = useSentimentAnalysis();


    const handleInputChange = (event) => {
        setInput(event.target.value);
    };

    useEffect(() => {
        if (response && taskStatus === 'Task completed') {
            onChatResponse(response);
        }
    }, [response, taskStatus, onChatResponse]);

    const handleButtonClick = async (event) => {
        event.preventDefault();
        try {
            await chat(input,selectedFormat);
        } catch (error) {
            console.error(error);
        }
    };

    const handleSentimentAnalysis = async () => {
        await analyzeSentiment(input);
    };

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');  
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');


    useEffect(() => {
        let message = '';
        let severity = 'info';
    
        if (chatError || sentimentError) {
            message = (chatError ? chatError.toString() : '') || (sentimentError ? sentimentError.toString() : '');
            severity = 'error';
        } else if (emotions) {
            message = Object.entries(emotions).map(([emotion, value]) => `${emotion}: ${(value * 100).toFixed(2)}%`).join(', ');
            message = `Top Emotions: ${message}`;
            severity = 'info';
        }

        if (message) {
            setSnackbarMessage(message);
            setSnackbarSeverity(severity);
            setOpenSnackbar(true);
        }
    }, [chatError, sentimentError, emotions]);

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    return (
        <Box
            component="form"
            id="chat-form"
            onSubmit={handleButtonClick}
            display="flex"
            flexDirection="column"
            gap={2}
        >
            <TextField
                id="input-field"
                label="Wat wil je in een script gieten?"
                multiline
                placeholder="Benoem een onderwerp (bijv. 'voxpop over Socrates') of deel een hele lap aan data/tekst."
                rows={4}
                value={input}
                onChange={handleInputChange}
                variant="outlined"
                disabled={loading} 
                InputProps={{
                    endAdornment: (
                        <div style={{ width: '20%', marginLeft: '20px', textAlign: 'right' }}>
                            <Button 
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={loading || taskStatus === 'Task in progress'}
                            >
                                {loading || taskStatus === 'Task in progress' ? (
                                    <>
                                        <CircularProgress size={24} />
                                        {taskStatus === 'Task in progress' ? 'Verwerken...' : 'Taak wordt verwerkt...'}
                                    </>
                                ) : taskId ? 'Opnieuw verzenden' : 'Maak er een script van'}
                            </Button>
                            <Button 
                                style={{ marginTop: '10px' }} 
                                variant="outlined"
                                onClick={handleSentimentAnalysis}
                                disabled={loading || sentimentLoading}
                            >
                                Analyse Sentiment
                            </Button>
                        </div>
                    ),
                }}
                inputProps={{ style: { resize: "both" } }}
            />

    <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
        <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
            {snackbarMessage}
        </Alert>
    </Snackbar>



    <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ChatForm;






